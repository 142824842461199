import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import { Main } from './Main';

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Main />
      ),
    },
  ]);

  return (
    <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />
  );
}

export default App;
